import React from "react"

import "./footer.scss"
import Logo from "../../images/jungmann-logo-inverted.svg"

const Footer = () => {
  const blockClasses = ["has-background-color-anthracite"]

  const classes = `footer ${[...blockClasses]}`

  return (
    <footer className={classes}>
      <div className="wrap">
        <img src={Logo} alt="Jungmann logo" width="175" height="22" />
        <p className="has-font-style-body-small">
          <address>Kruunuvuorenkatu 4. 00160 Helsinki</address>
        </p>

        <div class="mrec-contacts">
          <h3 class="has-font-style-button">Aukioloajat</h3>
          <div class="mrec-contacts__grid">
            <div class="mrec">
              <p class="has-font-style-body-small">
                Alepa
                <br />
                ma-la klo 6.30-23, su klo 9-23
                <br />
              </p>
            </div>
            <div class="mrec">
              <p class="has-font-style-body-small">
                Alko
                <br />
                ma-pe 10-21, la 10-18 ja su suljettu
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
