import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "../../images/jungmann-logo.svg"
import Icon from "../../images/jungmann-icon.svg"

import "./header.scss"

const Header = () => {
  const [isActive, setActive] = useState(false);

  const toggleMenu = () => {
    if (isActive) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  return (
    <header>
      <div className="wrap">
        <Link to="/" class="logo-container"><img src={Logo} alt="Jungmann logo" width="175" height="22" /></Link>
        <nav className={isActive ? 'is-active' : 'is-not-active'}>
          <ul>
            <li><Link to="/#palvelut" onClick={toggleMenu}>Palvelut</Link></li>
            <li><Link to="/#vuokra-asunnot" onClick={toggleMenu}>Vuokra-asunnot</Link></li>
            <li><Link to="/#sijainti" onClick={toggleMenu}>Sijainti</Link></li>
            <li><Link to="/#historia" onClick={toggleMenu}>Historia</Link></li>
            <li><Link to="/#ajankohtaista" onClick={toggleMenu}>Ajankohtaista</Link></li>
            <li class="mobile-only"><a href="/asukkaille">Asukkaille</a></li>
          </ul>
        </nav>
        <div class="nav__right">
          <a href="/asukkaille">Asukkaille</a>
          <button onClick={toggleMenu} aria-label="Toggle mobile menu" className={"mobile-menu-toggle " + (isActive ? 'is-active' : 'is-not-active')}><img src={Icon} alt="Jungmann logo" width="28" height="28" /></button>
        </div>
      </div>
    </header>
  )
}

export default Header
